.TextInput {
  width: 585px;
  height: 80px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 21px;
  font-family: 'Museo Sans Rounded';
  font-weight: 300;
  border-radius: 15px;
  border: 1px solid #ff614f;

  &::placeholder {
    color: #36518a;
  }

  &:focus {
    outline: none;
  }
}

.error {
  font-family: 'Museo Sans Rounded';
  font-weight: 500;
  color: red;
  color: red;
}

@media (max-width: 1024px) {
  .TextInput {
    width: 100%;
    height: 54px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .error {
    font-size: 14px;
    line-height: 14px;
  }
}
