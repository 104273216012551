.Textarea {
  width: 585px;
  height: 245px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  resize: none;
  font-size: 21px;
  font-family: 'Museo Sans Rounded';
  font-weight: 300;
  border-radius: 15px;
  border: 1px solid #ff614f;

  &::placeholder {
    color: #36518a;
  }

  &:focus {
    outline: none;
  }
}

.error {
  font-family: 'Museo Sans Rounded';
  font-weight: 500;
  color: red;
}

@media (max-width: 1024px) {
  .Textarea {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    font-size: 16px;
  }

  .error {
    font-size: 14px;
    line-height: 14px;
  }
}
