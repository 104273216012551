.MobileMenu {
  position: fixed;
  top: 79px;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background-color: #e4e6f2;

  &.visible {
    display: flex;
  }

  .mainNav {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-y: auto;

    .linksList {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        text-decoration: none;
        font-size: 26px;
        font-family: 'Museo Sans Rounded';
        color: rgb(54, 81, 138);
        font-weight: 900;

        &.active {
          color: #ff614f;
        }
      }
    }
  }
}
