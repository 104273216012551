.Shape {
  position: absolute;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;

  &.triangle {
    background-image: url(../../../../../assets/images/triangle.svg);
  }

  &.circle {
    background-image: url(../../../../../assets/images/circle.svg);
  }
}
