.Footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 123px;
  padding-bottom: 25px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: -90px;
    display: block;
    width: 850px;
    height: 770px;
    background-image: url(../../../assets/images/circle.svg),
      url(../../../assets/images/triangle.svg),
      url(../../../assets/images/section_bg.svg);
    background-repeat: no-repeat;
    background-position: 100px 210px, 720px 25px, top;
    background-size: 25px, 23px, contain;
    transform: rotate(16deg);
  }

  .copyright {
    position: relative;
    z-index: 1;
    margin: 0;
    font-size: 16.667px;
    font-family: 'Museo Sans Rounded';
    color: rgb(94, 106, 137);
  }
}

@media (max-width: 1024px) {
  .Footer {
    &::after {
      background-image: none, none, url(../../../assets/images/section_bg.svg);
    }
  }
}
