.TechList {
  .header {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 33px;
    padding-left: 10px;

    .title {
      margin: 0;
      font-size: 19.16px;
      font-family: 'Museo Sans Rounded';
      color: rgb(54, 81, 138);
      text-align: left;
      font-weight: bold;
      white-space: nowrap;
    }

    .icon {
      height: 29px;
    }
  }

  .techsList {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      height: 36px;
      padding-left: 16px;
      padding-right: 16px;
      font-size: 16.5px;
      font-family: 'Museo Sans Rounded';
      color: white;
      font-weight: bold;
      white-space: nowrap;
      background-color: #36518a;
      border-radius: 26px;
    }
  }
}

@media (max-width: 1024px) {
  .TechList {
    .techsList {
      li {
        height: 30px;
        font-size: 13px;
      }
    }
  }
}
