* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

html {
  padding: env(safe-area-inset);
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: local('Museo Sans Rounded'),
    url('./fonts/MuseoSansRounded-700.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: local('Museo Sans Rounded'),
    url('./fonts/MuseoSansRounded-500.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: local('Museo Sans Rounded'),
    url('./fonts/MuseoSansRounded-900.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: local('Museo Sans Rounded'),
    url('./fonts/MuseoSansRounded-300.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Square Sans Serif 7';
  src: local('Square Sans Serif 7'),
    url('./fonts/square_sans_serif_7.ttf') format('truetype');
}
