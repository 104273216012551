.Technologies {
  scroll-margin-top: 220px;

  .technologiesContainer {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    row-gap: 40px;
    column-gap: 50px;
    width: 100%;
    padding-left: 60px;
    padding-right: 35%;
    padding-bottom: 100px;
    background-image: url(../../../../assets/images/technologies/technologies_graphic.svg);
    background-repeat: no-repeat;
    background-position: center right -70px;
    background-size: unset;
  }
}

@media (max-width: 1660px) {
  .Technologies {
    .technologiesContainer {
      background-size: 40%;
    }
  }
}

@media (max-width: 1024px) {
  .Technologies {
    scroll-margin-top: 100px;

    .technologiesContainer {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      background-image: none;
    }
  }
}
