.Clients {
  scroll-margin-top: 90px;
  padding-top: 110px;
  padding-bottom: 35px;
  .clientsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 75px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

@media (max-width: 1024px) {
  .Clients {
    scroll-margin-top: 100px;
    padding-top: 0;
    padding-bottom: 100px;
  }
}
