.Header {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-left: 60px;
  padding-right: 35px;
  padding-bottom: 16px;
  background-color: white;

  .logo {
    height: 40px;
  }

  .burgerButton {
    display: none;
  }

  .mainNav {
    .linksList {
      display: flex;
      align-items: center;
      gap: 70px;
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        text-decoration: none;
        font-size: 20.83px;
        font-family: 'Museo Sans Rounded';
        color: rgb(54, 81, 138);
        font-weight: 900;

        &.active {
          color: #ff614f;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 0;
    padding-bottom: 16px;

    &.mobileMenuVisible {
      background-color: #e4e6f2;

      .logo {
        visibility: hidden;
      }

      .burgerButton {
        .burgerIcon {
          background-color: #e4e6f2;
          //  border: 1px solid red;
          transform: unset;

          &::after,
          &::before {
            height: 4px;
            background-color: #36518a;
            border-radius: 3px;
          }

          &::after {
            left: -5px;
            top: 9px;
            width: 32px;
            transform: rotate(45deg);
          }

          &::before {
            left: -5px;
            top: 9px;
            width: 32px;
            transform: rotate(-45deg);
          }
        }
      }
    }

    .navContainer {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .burgerButton {
      display: unset;
      width: 60px;
      height: 45px;
      position: relative;
      background-color: transparent;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg) scale(0.5);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      border: none;
      cursor: pointer;

      &.open {
        span:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }

        span:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        span:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }

      span {
        display: block;
        position: absolute;
        height: 9px;
        width: 100%;
        background-color: #36518a;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 18px;
        }

        &:nth-child(4) {
          top: 36px;
        }
      }
    }

    .mainNav {
      .linksList {
        .mainLink {
          display: none;
        }
      }
    }
  }
}
