.Service {
  &.active {
    .button {
      background-color: #94a6d6;
      p {
        color: white;
        font-weight: 500;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    gap: 45px;
    width: 365px;
    height: 85px;
    padding-left: 25px;
    border: 1px solid #94a6d6;
    border-radius: 8px;
    cursor: pointer;

    p {
      width: 210px;
      margin: 0;
      font-family: 'Museo Sans Rounded';
      font-size: 19px;
      font-weight: 300;
      color: rgb(72, 72, 72);
    }

    img {
      width: 43px;
    }
  }
}

@media (max-width: 1024px) {
  .Service {
    &.active {
      .button {
        background-color: #94a6d6;
        p {
        }
      }
    }

    .button {
      width: 100%;
      height: 60px;
      gap: 30px;
      padding-left: 20px;
      padding-right: 20px;

      p {
        width: unset;
        font-size: 16px;
      }

      img {
        transform: scale(0.8);
      }
    }

    .info {
      position: relative;
      margin-top: 20px;

      .iconsContainer {
        text-align: center;
        img {
          width: 75px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(../../../../../assets/images/services/background.svg);
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(var(--rotation));
        pointer-events: none;
      }

      p {
        position: relative;
        z-index: 1;
        margin: 0;
        margin-bottom: 20px;
        font-size: 16px;
        font-family: 'Museo Sans Rounded';
        color: black;
        font-weight: 300;
      }
    }
  }
}
