.Contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 160px;
  padding-bottom: 55px;

  .headerContainer {
    width: 100%;
  }

  .email {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 60px;
    font-family: 'Museo Sans Rounded';
    font-size: 21px;
    font-weight: 500;

    .emailIcon {
      width: 20px;
      margin-left: 15px;
      margin-right: 15px;
    }

    a {
      color: black;
    }
  }

  .heading {
    position: relative;
    display: flex;
    justify-content: center;
    width: 565px;
    margin-bottom: 75px;
    font-size: 21px;
    font-family: 'Museo Sans Rounded';
    color: rgb(255, 97, 79);

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 0.5px);
      left: 0;
      display: block;
      width: 230px;
      height: 1px;
      background-color: #ffb5ad;
    }

    &::after {
      left: unset;
      right: 0;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      button {
        width: 135px;
        height: 50px;
        font-size: 21px;
        font-family: 'Museo Sans Rounded';
        color: white;
        font-weight: bold;
        background-color: #ff614f;
        border-radius: 8px;
        border: none;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Contacts {
    scroll-margin-top: 100px;
    width: 100%;
    padding-top: 0;
    padding-bottom: 100px;

    .email {
      font-size: 17px;
    }

    .heading {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 18px;
      &::after,
      &::before {
        left: 20px;
        width: calc(50% - 60px);
      }

      &::after {
        left: unset;
        right: 20px;
      }
    }

    .form {
      width: 100%;
      max-width: 585px;
      padding-left: 20px;
      padding-right: 20px;
      .buttonContainer {
        button {
          width: 110px;
          height: 44px;
          font-size: 18px;
        }
      }
    }
  }
}
