.Background {
  position: absolute;
  top: calc(50% - 185px);
  left: -255px;
  z-index: 0;
  width: 410px;
  height: 370px;
  background-image: url(../../../../assets/images/triangle.svg),
    url(../../../../assets/images/circle.svg),
    url(../../../../assets/images/section_bg.svg);
  background-repeat: no-repeat;
  background-size: 20px, 20px, cover;
  background-position: top 80px right 90px, bottom 100px right 70px, center;

  &.right {
    left: unset;
    right: -255px;
    background-position: top 75px right 190px, bottom 260px right 40px, center;
  }
}

@media (max-width: 1024px) {
  .Background {
    display: none;
  }
}
