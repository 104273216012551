.SectionHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 125px;

  h2 {
    position: relative;
    z-index: 1;
    margin: 0;
    margin-bottom: 30px;
    font-size: 37.5px;
    font-family: 'Museo Sans Rounded';
    color: rgb(255, 97, 79);
    font-weight: 900;
  }

  p {
    position: relative;
    z-index: 1;
    width: 590px;
    margin: 0;
    font-size: 21px;
    font-family: 'Museo Sans Rounded';
    color: black;
    line-height: 1.2;
    text-align: center;
    font-weight: 300;
  }
}

@media (max-width: 1024px) {
  .SectionHeader {
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    h2 {
      font-size: 26px;
    }

    p {
      width: 100%;
      max-width: 100%;
      font-size: 18px;
    }
  }
}
