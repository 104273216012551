.LinkButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 47px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  background-color: #ff614f;
  border-radius: 5px;
}

a {
  font-family: 'Museo Sans Rounded';
  text-decoration: none;
}
