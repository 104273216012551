.Services {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  scroll-margin-top: 57px;
  padding-top: 75px;
  padding-bottom: 270px;
  overflow: hidden;

  .leftBackground {
    position: absolute;
    left: 72px;
    width: 100px;
    height: 125px;
    background-image: url(../../../../assets/images/circle.svg),
      url(../../../../assets/images/circle.svg);
    background-size: 16px 16px, 19px 19px;
    background-repeat: no-repeat;
    background-position: left bottom, right bottom 36px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 5px;
      display: block;
      width: 20px;
      height: 25px;
      background-repeat: no-repeat;
      background-image: url(../../../../assets/images/triangle.svg);
      transform: rotate(15deg);
    }
  }

  .rightBackground {
    position: absolute;
    right: 69px;
    width: 90px;
    height: 98px;
    background-image: url(../../../../assets/images/circle.svg);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: top right;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-image: url(../../../../assets/images/triangle.svg);
      transform: rotate(15deg);
    }

    &::before {
      content: '';
      position: absolute;
      top: 22px;
      left: 0;
      display: block;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-image: url(../../../../assets/images/triangle.svg);
      transform: rotate(-25deg);
    }
  }

  .servicesInfo {
    display: flex;
    gap: 110px;
    max-width: 1500px;
    padding-left: 72px;

    .serviceDescription {
      position: relative;
      padding-right: 69px;

      &.mobile {
        background-image: url(../../../../assets/images/services/ios_android_icons.png);
        background-repeat: no-repeat;
        background-position: bottom right calc(50% + 35px);
        background-size: 90px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 33px;
        right: 11px;
        display: block;
        width: 595px;
        height: 540px;
        background-image: url(../../../../assets/images/services/background.svg);
        transform: rotate(var(--rotation));
      }

      h3 {
        margin: 0;
        margin-bottom: 70px;
        font-size: 29px;
        font-family: 'Museo Sans Rounded';
        color: rgb(255, 97, 79);
        font-weight: bold;
        text-align: center;
      }

      .text {
        p {
          margin: 0;
          margin-bottom: 30px;
          font-size: 18.75px;
          font-family: 'Museo Sans Rounded';
          color: black;
          font-weight: 300;
        }
      }
    }

    .servicesList {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 0;
      padding: 0;
      list-style: none;

      &::after {
        content: '';
        position: absolute;
        right: -138px;
        bottom: -48px;
        display: block;
        width: 28px;
        height: 28px;
        background-repeat: no-repeat;
        background-image: url(../../../../assets/images/circle.svg);
        transform: rotate(10deg);
      }
    }
  }
}

@media (max-width: 1024px) {
  .Services {
    display: block;
    padding-top: 0;
    padding-bottom: 100px;
    scroll-margin-top: 89px;
    overflow: unset;

    .leftBackground {
      display: none;
    }

    .rightBackground {
      display: none;
    }

    .servicesInfo {
      padding-left: 20px;
      padding-right: 20px;
      .serviceDescription {
        display: none;
      }

      .servicesList {
        width: 100%;
      }
    }
  }
}
