.Client {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 850px;
  .image {
    width: 100%;
    margin-bottom: 15px;
  }

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-bottom: 25px;

    .icon {
      width: 50px;
      height: auto;
    }

    h3 {
      margin: 0;
      font-size: 25px;
      font-family: 'Museo Sans Rounded';
      color: rgb(255, 97, 79);
    }
  }

  .text {
    p {
      margin: 0;
      margin-bottom: 30px;
      font-size: 18.75px;
      font-family: 'Museo Sans Rounded';
      font-weight: 300;
    }
  }
}

@media (max-width: 849px) {
  .Client {
    .header {
      padding-left: 20px;
      padding-right: 20px;
    }

    .text {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
