.Features2 {
  display: flex;
  justify-content: center;
  padding-left: 65px;
  padding-right: 60px;

  .featuresList {
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 0;
    padding: 0;
    list-style: none;

    .feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      height: 145px;
      padding-left: 30px;
      padding-right: 30px;
      border-right: 1px solid #c3cadc;

      &:first-child {
        align-items: flex-start;
        padding-left: 0;
      }

      &:last-child {
        align-items: flex-end;
        padding-right: 0;
        border-right: none;
      }

      .header {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
        margin-bottom: 15px;

        h3 {
          margin: 0;
          font-size: 21px;
          font-family: 'Museo Sans Rounded';
          color: rgb(53, 82, 140);
          font-weight: bold;
        }

        img {
          width: 40px;
          height: 40px;
        }
      }

      p {
        margin: 0;
        font-size: 16.667px;
        font-family: 'Museo Sans Rounded';
        font-weight: 300;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Features2 {
    padding-left: 0;
    padding-right: 0;

    .featuresList {
      flex-direction: column;
      gap: 30px;
      .feature {
        align-items: unset;
        padding: 0;
        border-right: 0;

        transform: scale(0.75);

        &:first-child {
          align-items: unset;
        }

        &:last-child {
          align-items: unset;
        }
      }
    }
  }
}
