.Process {
  position: relative;
  scroll-margin-top: 160px;
  padding-bottom: 135px;

  .complienceListContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
    .complienceList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 685px;
      margin: 0;
      padding: 0;
      list-style: none;

      .icon {
        transform: scale(0.9);
      }
    }
  }

  .processImageContainer {
    display: flex;
    justify-content: center;
    padding-left: 80px;
    padding-right: 70px;
    margin-top: 100px;
    margin-bottom: 190px;

    img {
      width: 100%;
      max-width: 1000px;
    }
  }
}

@media (max-width: 1024px) {
  .Process {
    scroll-margin-top: 100px;
    padding-bottom: 100px;
    padding-top: 0;

    .complienceListContainer {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 75px;

      .complienceList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 10px;

        .icon {
          transform: scale(0.7);
        }
      }
    }

    .processImageContainer {
      margin-top: 75px;
      margin-bottom: 75px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
