.Features {
  display: flex;
  justify-content: center;
  padding-left: 110px;
  padding-right: 110px;

  .featuresList {
    display: flex;
    justify-content: space-between;
    flex: 1;
    max-width: 1280px;
    margin: 0;
    padding: 0;
    list-style: none;

    .feature {
      display: flex;
      gap: 12px;

      img {
        position: relative;
        top: 3px;
        height: 30px;
      }

      h3 {
        margin: 0;
        font-size: 16.667px;
        font-family: 'Museo Sans Rounded';
        color: rgb(94, 116, 161);
        font-weight: 900;
      }

      p {
        margin: 0;
        font-size: 16.667px;
        font-family: 'Museo Sans Rounded';
        color: rgb(118, 141, 200);
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Features {
    padding-left: 0;
    padding-right: 0;
    .featuresList {
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .feature {
        width: 100%;
        max-width: 450px;
        gap: 40px;
        transform: scale(0.75);

        .imageContainer {
          min-width: 30px;
        }

        p {
          br {
            display: none;
          }
        }
      }
    }
  }
}
